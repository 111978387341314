<template>
  <div id="app">
    <!-- 導航欄 -->
    <nav class="bg-blue-600 text-white p-4 fixed w-full z-10">
      <div class="container mx-auto flex flex-wrap items-center justify-between">
        <div class="flex items-center">
          <!-- Logo -->
          <img src="/logo.png" alt="USDT交易平台" class="h-20 w-20 mr-3">
          <h1 class="text-2xl font-bold">S Coin</h1>
        </div>
        <button @click="toggleMenu" class="md:hidden">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
        <ul :class="{ 'hidden': !menuOpen, 'md:flex': true }"
          class="w-full md:w-auto flex-grow md:flex md:items-center md:justify-end space-y-2 md:space-y-0 md:space-x-4 mt-4 md:mt-0">
          <li><a href="#home"
              class="block py-2 px-4 hover:bg-blue-700 md:hover:bg-transparent md:hover:text-blue-200">首頁</a></li>
          <li><a href="#services"
              class="block py-2 px-4 hover:bg-blue-700 md:hover:bg-transparent md:hover:text-blue-200">服務</a></li>
          <li><a href="#how-it-works"
              class="block py-2 px-4 hover:bg-blue-700 md:hover:bg-transparent md:hover:text-blue-200">操作流程</a></li>
          <li><a href="#advantages"
              class="block py-2 px-4 hover:bg-blue-700 md:hover:bg-transparent md:hover:text-blue-200">我們的優勢</a></li>
          <li><a href="#faq"
              class="block py-2 px-4 hover:bg-blue-700 md:hover:bg-transparent md:hover:text-blue-200">常見問題</a></li>
        </ul>
      </div>
    </nav>

    <!-- 首頁區塊 -->
    <section id="home" class="bg-gradient-to-r from-blue-500 to-blue-700 text-white py-32 mt-16">
      <div class="container mx-auto px-4 text-center">
        <h2 class="text-4xl md:text-5xl font-bold mb-6">安全可靠的USDT交易平台</h2>
        <p class="text-xl mb-8 max-w-2xl mx-auto">連接買家和賣家，提供最佳的USDT交易體驗。我們的平台確保每筆交易的安全性和效率，讓您輕鬆進行數字資產交易。</p>
        <div class="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
          <a :href="lineLink"
            class="bg-white text-blue-600 py-3 px-8 rounded-full text-lg font-semibold hover:bg-blue-100 transition duration-300">開始交易</a>
          <a href="#how-it-works"
            class="border-2 border-white text-white py-3 px-8 rounded-full text-lg font-semibold hover:bg-white hover:text-blue-600 transition duration-300">了解更多</a>
        </div>
      </div>
    </section>

    <!-- 服務區塊 -->
    <section id="services" class="py-20 bg-gray-100">
      <div class="container mx-auto px-4">
        <h2 class="text-3xl md:text-4xl font-bold text-center mb-16">我們的服務</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div class="bg-white p-6 rounded-lg shadow-md transition duration-300 hover:shadow-xl">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 text-blue-500 mb-6 mx-auto" fill="none"
              viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <h3 class="text-2xl font-semibold mb-4 text-center">買家服務</h3>
            <ul class="space-y-2">
              <li class="flex items-center"><svg class="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>安全的USDT購買渠道</li>
              <li class="flex items-center"><svg class="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>多種支付方式</li>
              <li class="flex items-center"><svg class="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>實時市場價格</li>
              <li class="flex items-center"><svg class="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>24/7客戶支持</li>
            </ul>
          </div>
          <div class="bg-white p-8 rounded-lg shadow-md transition duration-300 hover:shadow-xl">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 text-blue-500 mb-6 mx-auto" fill="none"
              viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
            </svg>
            <h3 class="text-2xl font-semibold mb-4 text-center">賣家服務</h3>
            <ul class="space-y-2">
              <li class="flex items-center"><svg class="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>快速匹配潛在買家</li>
              <li class="flex items-center"><svg class="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>靈活的定價策略</li>
              <li class="flex items-center"><svg class="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>安全的資金轉賬</li>
              <li class="flex items-center"><svg class="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>交易數據分析</li>
            </ul>
          </div>
          <div class="bg-white p-8 rounded-lg shadow-md transition duration-300 hover:shadow-xl">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 text-blue-500 mb-6 mx-auto" fill="none"
              viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
            </svg>
            <h3 class="text-2xl font-semibold mb-4 text-center">交易保障</h3>
            <ul class="space-y-2">
              <li class="flex items-center"><svg class="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>安全的託管服務</li>
              <li class="flex items-center"><svg class="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>交易糾紛解決</li>
              <li class="flex items-center"><svg class="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>身份驗證系統</li>
              <li class="flex items-center"><svg class="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>資金安全保障</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- 操作流程區塊 -->
    <section id="how-it-works" class="py-20">
      <div class="container mx-auto px-4">
        <h2 class="text-3xl md:text-4xl font-bold text-center mb-16">操作流程</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          <div class="text-center">
            <div
              class="bg-blue-500 text-white w-20 h-20 rounded-full flex items-center justify-center text-3xl font-bold mx-auto mb-4">
              1</div>
            <h3 class="text-xl font-semibold mb-2">註冊賬戶</h3>
            <p class="text-gray-600">簡單快速地創建您的交易賬戶，只需幾分鐘即可完成</p>
          </div>
          <div class="text-center">
            <div
              class="bg-blue-500 text-white w-20 h-20 rounded-full flex items-center justify-center text-3xl font-bold mx-auto mb-4">
              2</div>
            <h3 class="text-xl font-semibold mb-2">發布交易需求</h3>
            <p class="text-gray-600">選擇買入或賣出USDT，設置您的理想價格和數量</p>
          </div>
          <div class="text-center">
            <div
              class="bg-blue-500 text-white w-20 h-20 rounded-full flex items-center justify-center text-3xl font-bold mx-auto mb-4">
              3</div>
            <h3 class="text-xl font-semibold mb-2">匹配交易</h3>
            <p class="text-gray-600">我們的系統會自動為您匹配最佳的交易對象</p>
          </div>
          <div class="text-center">
            <div
              class="bg-blue-500 text-white w-20 h-20 rounded-full flex items-center justify-center text-3xl font-bold mx-auto mb-4">
              4</div>
            <h3 class="text-xl font-semibold mb-2">完成交易</h3>
            <p class="text-gray-600">通過我們安全的託管服務完成USDT交易</p>
          </div>
        </div>
      </div>
    </section>

    <!-- 我們的優勢區塊 -->
    <section id="advantages" class="py-20 bg-gray-100">
      <div class="container mx-auto px-4">
        <h2 class="text-3xl md:text-4xl font-bold text-center mb-16">我們的優勢</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div class="flex items-start">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-blue-500 mr-4 flex-shrink-0" fill="none"
              viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
            </svg>
            <div>
              <h3 class="text-2xl font-semibold mb-2">安全可靠</h3>
              <p class="text-gray-600">我們採用先進的加密技術和多重驗證機制，確保每筆交易的安全性。您的資金和個人信息始終受到嚴格保護。</p>
            </div>
          </div>
          <div class="flex items-start">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-blue-500 mr-4 flex-shrink-0" fill="none"
              viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
            <div>
              <h3 class="text-2xl font-semibold mb-2">快速交易</h3>
              <p class="text-gray-600">我們的智能匹配系統能夠迅速為您找到最佳的交易對象，大大縮短交易時間，提高效率。</p>
            </div>
          </div>
          <div class="flex items-start">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-blue-500 mr-4 flex-shrink-0" fill="none"
              viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
            <div>
              <h3 class="text-2xl font-semibold mb-2">低手續費</h3>
              <p class="text-gray-600">我們提供業內最具競爭力的手續費率，讓您在每筆交易中都能最大化收益。</p>
            </div>
          </div>
          <div class="flex items-start">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-blue-500 mr-4 flex-shrink-0" fill="none"
              viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
            <div>
              <h3 class="text-2xl font-semibold mb-2">24/7 客戶支持</h3>
              <p class="text-gray-600">我們的專業客服團隊全天候為您服務，隨時解答您的問題，協助您順利完成每筆交易。</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 常見問題區塊 -->
    <section id="faq" class="py-20">
      <div class="container mx-auto px-4">
        <h2 class="text-3xl md:text-4xl font-bold text-center mb-16">常見問題</h2>
        <div class="space-y-8">
          <div class="bg-white shadow-md rounded-lg p-6">
            <h3 class="text-xl font-semibold mb-2">如何開始使用USDT交易平台？</h3>
            <p class="text-gray-600">首先，您需要在我們的平台上註冊一個賬戶。註冊過程簡單快速，只需提供基本信息並完成身份驗證即可。完成註冊後，您就可以開始買賣USDT了。</p>
          </div>
          <div class="bg-white shadow-md rounded-lg p-6">
            <h3 class="text-xl font-semibold mb-2">平台支持哪些支付方式？</h3>
            <p class="text-gray-600">我們支持多種支付方式，包括銀行轉賬、現金付款等。具體可用的支付方式會在您交易時跟賣家之間協調，您可以選擇最適合自己的方式。</p>
          </div>
          <div class="bg-white shadow-md rounded-lg p-6">
            <h3 class="text-xl font-semibold mb-2">如何確保交易的安全性？</h3>
            <p class="text-gray-600">我們採用先進的加密技術和多重驗證機制來保護您的賬戶和交易。此外，我們還提供安全的託管服務，確保買賣雙方的權益得到保障。只有在雙方確認交易完成後，資金才會被釋放。
            </p>
          </div>
          <div class="bg-white shadow-md rounded-lg p-6">
            <h3 class="text-xl font-semibold mb-2">交易手續費是如何計算的？</h3>
            <p class="text-gray-600">我們的手續費率是業內最具競爭力的。具體費率會根據交易量和用戶等級有所不同。您可以在交易前查看具體的手續費信息。我們承諾，所有費用都會清晰透明地顯示，絕不會有隱藏費用。
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- 行動呼籲 -->
    <section class="bg-blue-600 text-white py-20">
      <div class="container mx-auto px-4 text-center">
        <h2 class="text-3xl md:text-4xl font-bold mb-8">準備開始您的USDT交易之旅了嗎？</h2>
        <p class="text-xl mb-12 max-w-2xl mx-auto">加入我們的平台，體驗安全、快速、低成本的USDT交易。無論您是買家還是賣家，我們都能為您提供最佳的交易體驗。</p>
        <a :href="lineLink"
          class="bg-white text-blue-600 py-3 px-8 rounded-full text-lg font-semibold hover:bg-blue-100 transition duration-300">立即加入</a>
      </div>
    </section>

    <!-- 頁腳 -->
    <footer class="bg-gray-800 text-white py-12">
      <div class="container mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-8">
        <div>
          <h3 class="text-xl font-semibold mb-4">關於我們</h3>
          <p class="text-gray-400">S Coin致力於提供安全、快速、便捷的數字貨幣交易服務，為用戶創造價值。我們的目標是成為最值得信賴的USDT交易媒合平台。</p>
        </div>
        <div>
          <h3 class="text-xl font-semibold mb-4">快速鏈接</h3>
          <ul class="space-y-2">
            <li><a href="#home" class="text-gray-400 hover:text-white">首頁</a></li>
            <li><a href="#services" class="text-gray-400 hover:text-white">服務</a></li>
            <li><a href="#how-it-works" class="text-gray-400 hover:text-white">操作流程</a></li>
            <li><a href="#advantages" class="text-gray-400 hover:text-white">我們的優勢</a></li>
            <li><a href="#faq" class="text-gray-400 hover:text-white">常見問題</a></li>
          </ul>
        </div>
        <div>
          <h3 class="text-xl font-semibold mb-4">聯繫我們</h3>
          <ul class="space-y-2">
            <li class="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-400" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              support@usdtplatform.com
            </li>
            <li class="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-400" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
              </svg>
              +1 (555) 123-4567
            </li>
            <li class="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-400" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              123 Trading Street, Crypto City, CC 12345
            </li>
          </ul>
        </div>
      </div>
      <div class="mt-8 pt-8 border-t border-gray-700 text-center">
        <p class="text-gray-400">&copy; 2024 S Coin 版權所有</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuOpen: false,
      lineLink: 'https://lin.ee/KPSENUN',
    }
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      this.menuOpen = false;
    }
  },
  mounted() {
    // 監聽窗口大小變化，在大屏幕時關閉菜單
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 768) {  // 768px 是 Tailwind 的 md 斷點
        this.closeMenu();
      }
    });

    // 監聽滾動事件，滾動時關閉菜單
    window.addEventListener('scroll', this.closeMenu);

    // 為每個導航鏈接添加點擊事件，點擊後關閉菜單
    const navLinks = document.querySelectorAll('nav a');
    navLinks.forEach(link => {
      link.addEventListener('click', this.closeMenu);
    });
  },
  beforeUnmount() {
    // 移除事件監聽器以防止內存洩漏
    window.removeEventListener('resize', this.closeMenu);
    window.removeEventListener('scroll', this.closeMenu);
  }
}
</script>

<style scoped>
/* 可以在這裡添加任何額外的自定義樣式 */
</style>